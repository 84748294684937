import { DOCUMENT } from '@angular/common';
import { Subscription, auditTime, fromEvent } from 'rxjs';
import { Directive, ElementRef, Input, booleanAttribute, inject, OnDestroy, NgZone, output } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[vmrClickOutside]'
})
export class VmrClickOutside implements OnDestroy {
  private _clickSubscription$?: Subscription;
  private readonly _ngZone = inject(NgZone);
  private readonly _document = inject(DOCUMENT);
  private readonly _elementRef = inject<ElementRef<HTMLElement>>(ElementRef);

  readonly clickOutside = output<void>({alias: 'vmrClickOutside'});

  /** Controls creation/destruction of click event subscription. */
  @Input({ alias: 'vmrClickOutsideActive', transform: booleanAttribute })
  get active(): boolean {
    return this._active;
  }
  set active(value: boolean) {
    this._active = value;
    this._active ? this._subscribe() : this._unsubscribe();
  }
  private _active = false;

  ngOnDestroy(): void {
    this._unsubscribe();
  }

  private _subscribe(): void {
    this._unsubscribe();
    const clickOutside$ = fromEvent<PointerEvent>(this._document, 'click').pipe(auditTime(10));

    this._ngZone.runOutsideAngular(() => {
      this._clickSubscription$ = clickOutside$.subscribe((e) => {
        if (!this._elementRef.nativeElement.contains(e.target as Node)) {
          this._ngZone.run(() => this.clickOutside.emit());
        }
      });
    });
  }

  private _unsubscribe(): void {
    this._clickSubscription$?.unsubscribe();
  }
}
/* eslint-disable no-empty */
import {InjectionToken} from '@angular/core';

export const LOCAL_STORAGE = new InjectionToken<Storage>('LOCAL_STORAGE', {
  providedIn: 'root',
  factory: () => new LocalStorage()
});

/**
 * LocalStorage wraps native localStorage object in order to provide safety from issues like
 * browser restrictions and storage limits.
 *
 * TODO: provide error handling.
 */
class LocalStorage implements Storage {
  get length(): number {
    try {
      return localStorage.length;
    } catch {
      return 0;
    }
  }

  clear(): void {
    try {
      localStorage.clear();
    } catch {}
  }

  removeItem(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch {}
  }

  key(index: number): string | null {
    try {
      return localStorage.key(index);
    } catch {
      return null;
    }
  }

  getItem(key: string): string | null {
    try {
      return localStorage.getItem(key);
    } catch {
      return null;
    }
  }

  setItem(key: string, value: string): void {
    try {
      localStorage.setItem(key, value);
    } catch {}
  }
}
import {
  Type,
  inject,
  Injector,
  Injectable,
  ComponentRef,
  ApplicationRef,
  createComponent,
  EnvironmentInjector
} from '@angular/core';

/** Apps in which we've loaded styles. */
const _appLoaderMap = new WeakMap<
  ApplicationRef,
  {
    /** Style loaders that have been added. */
    loaders: Set<Type<unknown>>;
    /** References to the instantiated loaders. */
    refs: ComponentRef<unknown>[];
  }
>();

/**
 * Service that loads structural styles dynamically and ensures that they're only loaded once per app.
 * Note: this is modeled after a private service in Angular Material CDK which is not exported as part of its public API.
 */
@Injectable({
  providedIn: 'root'
})
export class StyleLoaderService {
  private _appRef?: ApplicationRef;
  private _injector = inject(Injector);
  private _environmentInjector = inject(EnvironmentInjector);

  /**
   * Loads a set of styles.
   * @param loader Component which will be instantiated to load the styles.
   */
  load(loader: Type<unknown>): void {
    // Resolve the app ref lazily to avoid circular dependency errors if this is called too early.
    const appRef = (this._appRef = this._appRef || this._injector.get(ApplicationRef));
    let data = _appLoaderMap.get(appRef);

    // If we haven't loaded for this app before, we have to initialize it.
    if (!data) {
      data = {
        refs: [],
        loaders: new Set()
      };

      _appLoaderMap.set(appRef, data);

      appRef.onDestroy(() => {
        _appLoaderMap.get(appRef)?.refs.forEach(ref => ref.destroy());
        _appLoaderMap.delete(appRef);
      });
    }

    // If the loader hasn't been loaded before, we need to instatiate it.
    if (!data.loaders.has(loader)) {
      data.loaders.add(loader);
      data.refs.push(createComponent(loader, {environmentInjector: this._environmentInjector}));
    }
  }
}
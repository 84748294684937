import { AnimationTriggerMetadata, transition, trigger } from '@angular/animations';

/**
 * Key for ANIMATION_MODULE_TYPE DI token that indicates noop animations module is loaded.
 */
export const NOOP_ANIMATION_MODE = 'NoopAnimations';

export const NOOP_ANIMATION_CLASS = 'vmr-noop-animation';

/**
 * Animations/noops that prevent animations from running.
 */
export const disableAnimations: {
  readonly disableInitial: AnimationTriggerMetadata;
} = {
  disableInitial: trigger('disableInitial', [
    transition(':enter', [])
  ])
};
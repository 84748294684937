import { style, trigger, animate, keyframes, transition, type AnimationTriggerMetadata } from '@angular/animations';

const FADE_IN_OUT_TIMINGS = '150ms ease-in';

/**
 * Animations to transition an element when added/removed from the DOM.
 */
export const fadeAnimations: {
  readonly fadeIn: AnimationTriggerMetadata;
  readonly fadeInOut: AnimationTriggerMetadata;
} = {
  fadeIn: trigger('fadeIn', [
    transition(':enter', [animate(FADE_IN_OUT_TIMINGS, keyframes([style({ opacity: 0 }), style({ opacity: 1 })]))])
  ]),
  fadeInOut: trigger('fadeInOut', [
    transition(':enter', [style({ opacity: 0 }), animate(FADE_IN_OUT_TIMINGS, style({ opacity: 1 }))]),
    transition(':leave', [animate(FADE_IN_OUT_TIMINGS, style({ opacity: 0 }))])
  ])
};
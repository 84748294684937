import { animate, style, transition, trigger, AnimationTriggerMetadata } from '@angular/animations';

/** Time and timing curve for expansion panel animations. */
export const BODY_EXPANSION_ANIMATION_TIMING = '225ms cubic-bezier(0.4,0.0,0.2,1)';

/** Expand & collapse animations. */
export const expansionAnimations: {
  readonly bodyExpansion: AnimationTriggerMetadata;
} = {
  /** Animations that collapses/expands the content of an element when added/removed from DOM. */
  bodyExpansion: trigger('bodyExpansion', [
    transition(':enter', [
      style({ opacity: 0, height: '0px' }),
      animate(BODY_EXPANSION_ANIMATION_TIMING, style({ opacity: 1, height: '*' }))
    ]),
    transition(':leave', [animate(BODY_EXPANSION_ANIMATION_TIMING, style({ opacity: 0, height: '0px' }))])
  ])
};

import { InjectionToken } from '@angular/core';

/** Default 'global' config that can be overridden. */
export interface VmrGlobalConfig {
  /** Determines using <i> tag for fontawesome instead of <fa-icon>. */
  useIconTag: boolean;
  /** Layout container `background-color`. Default is `#efefef` because we started with fleet (TODO: should default to `white`). */
  appBackgroundColor: string;
  /** Breakpoints object to hold media queries used within the library. */
  breakpoints: {
    /** Default navigation_mobile media query used to toggle mobile layout views. */
    NAV_MOBILE: string;
  };
}

/** Injection token to be used to override the default config for global layout related state. */
export const VMR_GLOBAL_CONFIG = new InjectionToken<VmrGlobalConfig>(
  'vmr-global-config',
  {
    providedIn: 'root',
    factory: VMR_GLOBAL_CONFIG_FACTORY
  },
);

function VMR_GLOBAL_CONFIG_FACTORY(): VmrGlobalConfig {
  return {
    useIconTag: false,
    appBackgroundColor: '#efefef',
    breakpoints: {
      NAV_MOBILE: '(max-width: 991px)'
    }
  };
}